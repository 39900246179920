import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    fallback: {
      maxWidth: (isDesktop) => (isDesktop ? "65%" : "calc(100vw - 32px)"),
      padding: (isDesktop) => (isDesktop ? "70px 0 0 80px" : "0px"),
      "& img": {
        padding: "0 0 24px 0",
        maxWidth: "100%",
      },
      "& p": {
        fontFamily: "Helvetica Neue",
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "22px",
        color: "rgba(0, 0, 0, 0.85)",
        padding: "0 0 20px 0",
      },
      "& > span": {
        display: "block",
        fontFamily: "Helvetica Neue",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "21px",
        color: "#272522",
        padding: "0 0 24px 0",
      },
    },
    image: {
      width: "300px",
    },
    wrapper: {
      color: "rgba(0, 0, 0, 0.85)",
      width: "240px",
      margin: ({ isDesktop }) =>
        isDesktop ? "52px 0 0 128px" : "52px auto 0 auto",
    },
    title: {
      margin: "24px 0 20px 0",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 700,
    },
    description: {
      fontSize: "16px",
      lineHeight: "19px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
