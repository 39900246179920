import constants from "constants/constants";
import React from "react";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import {
  CustomerEligibility,
  StatusField,
  ValidityField,
} from "./components/CustomColumns";

export const columnConfig = ({ showValidityPopup, showEligibilityPopup }) => [
  {
    field: "code",
    headerName: "Discount Code",
    noWrap: true,
    sortable: false,
  },
  {
    field: "applicability",
    headerName: "Validity",
    valueFormatter: (record) => (
      <ValidityField record={record} showValidityPopup={showValidityPopup} />
    ),
    noWrap: true,
    sortable: false,
  },
  {
    field: "usage_count",
    headerName: "No. of times used",
    noWrap: true,
    emptyField: "N/A",
    sortable: false,
  },
  {
    field: "usage_limit",
    headerName: "Usage Limit",
    noWrap: true,
    sortable: false,
  },
  {
    field: "applicability",
    headerName: "Customer eligibility",
    valueFormatter: (record) => (
      <CustomerEligibility
        record={record}
        showEligibilityPopup={showEligibilityPopup}
      />
    ),
    noWrap: true,
    sortable: false,
  },
  {
    field: "value",
    headerName: "Discount Value",
    valueFormatter: (record) => (
      <span>
        {record.type === 1
          ? `${record.value}%`
          : `${getUserCurrencySymbol(
              constants.CURRENCY_SYMBOL_MAP[record.currency]
            )} ${record.value}`}
      </span>
    ),
    noWrap: true,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    valueFormatter: (record) => <StatusField record={record} />,
    noWrap: true,
    sortable: false,
    hidden: true,
  },
];
