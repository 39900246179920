import React from "react";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./styles";
import {
  coupon_api_keys,
  coupon_labels,
} from "ui/pages/discounts/discounts.constants";
import { Field } from "react-final-form";
import { ExlyInput, ExlyTextarea } from "common/form";
import CouponPreviewCarousel from "../CouponPreviewCarousel/CouponPreviewCarousel";
import { required } from "features/Crm/modules/AddCustomerModal/utils/AddCustomer.validations";

const CouponFormFields = ({ values }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  return (
    <div className={classes.couponFormWrap}>
      <CouponPreviewCarousel values={values} />

      <Field
        name="discountCodeName"
        label={coupon_labels.discount_code}
        size="small"
        component={ExlyInput}
        fullWidth={true}
        disabled={true}
        wrapperClassName={classes.mb}
      />
      <Field
        name={coupon_api_keys.header}
        label={coupon_labels.header}
        validate={required}
        size="small"
        component={ExlyInput}
        fullWidth={true}
        placeholder={coupon_labels.header_placeholder}
        wrapperClassName={classes.mb}
      />
      <Field
        name={coupon_api_keys.tagline}
        label={coupon_labels.tagline}
        validate={required}
        size="small"
        component={ExlyInput}
        fullWidth={true}
        placeholder={coupon_labels.tagline_placeholder}
        wrapperClassName={classes.mb}
      />
      <Field
        name={coupon_api_keys.description}
        label={coupon_labels.description}
        validate={required}
        size="small"
        component={ExlyTextarea}
        minHeight="82px"
        minWidth="100%"
        maxWidth="100%"
        placeholder={coupon_labels.description_placeholder}
        wrapperClassName={classes.mb}
      />
      <Field
        name={coupon_api_keys.button_text}
        label={coupon_labels.button_text}
        validate={required}
        size="small"
        component={ExlyInput}
        fullWidth={true}
        placeholder={coupon_labels.button_text_placeholder}
        wrapperClassName={classes.mb}
      />
    </div>
  );
};

export default CouponFormFields;
