import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import { Chip, Ribbon, Button } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import moment from "moment";
import React from "react";
import { getUserCurrencySymbol, isInternationalTimezone } from "utils/AuthUtil";
import { DateConvert } from "utils/Utils";
import { is_empty } from "utils/validations";
import ScheduleIcon from "../../../../assets/images/schedule.svg";
import { useStyles } from "./limitedOfferComponent.styles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import {
  limited_time_offer_statuses,
  limited_time_offer_statuses_data,
} from "../constants";

export const getCurrencySymbol = (record) => {
  return getUserCurrencySymbol(
    !isInternationalTimezone() && record.region === constants.domestic_region
      ? constants.inr_currency
      : constants.usd_currency
  );
};

export const MoneyField = ({ record = {}, source }) => {
  return (
    <span>
      {((record[source] || record[source] === 0) &&
        `${getCurrencySymbol(record)} ${record[source]}`) ||
        "N/A"}
    </span>
  );
};

export const DateTimeField = ({ record = {}, source }) => {
  if (is_empty(record[source])) return "N/A";
  return typeof record[source] === "number"
    ? moment.unix(record[source]).format(`MMM DD, YYYY, hh:mm${"\u00A0"}A`)
    : DateConvert(record, source, "time");
};

export const StatusField = ({ record }) => {
  const classes = useStyles();

  let status, style, icon;
  if (record.status === limited_time_offer_statuses.scheduled) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.scheduled]
        .label;
    style = classes.statusScheduled;
    icon = <img src={ScheduleIcon} />;
  } else if (record.status === limited_time_offer_statuses.active) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.active]
        .label;
    style = classes.statusActive;
  } else if (record.status === limited_time_offer_statuses.expired) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.expired]
        .label;
    style = classes.statusExpired;
  }
  return <Chip icon={icon} label={status} chipClassName={style} />;
};

export const getStatus = (record) => {
  let status;
  if (record.status === limited_time_offer_statuses.scheduled) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.scheduled]
        .label;
  } else if (record.status === limited_time_offer_statuses.active) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.active]
        .label;
  } else if (record.status === limited_time_offer_statuses.expired) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.expired]
        .label;
  }
  return status;
};

export const MobileStatusField = ({ record }) => {
  let status, color;
  if (record.status === limited_time_offer_statuses.scheduled) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.scheduled]
        .label;
    color = "warning";
  } else if (record.status === limited_time_offer_statuses.active) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.active]
        .label;
    color = "success";
  } else if (record.status === limited_time_offer_statuses.expired) {
    status =
      limited_time_offer_statuses_data[limited_time_offer_statuses.expired]
        .label;
    color = "danger";
  }

  return (
    <Ribbon
      top="50%"
      translateY="-50%"
      position="left"
      label={status}
      color={color}
    />
  );
};

export const PrimaryActionField = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.actionButton}>
      <MoreIcon />
    </IconButton>
  );
};

export const MoreActionsButton = ({ label, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      size="thin"
      color="link"
      variant="text"
      disableTouchRipple={true}
      className={classes.seeMoreBtn}
      onClick={onClick}
    >
      <span>{label}</span>
      <ChevronRightRoundedIcon />
    </Button>
  );
};

export const DateField = ({ record = {}, source, fromTable }) => {
  const replaceUTC = record[source]?.replace("T", " ")?.replace("Z", "");
  const dateFormat = replaceUTC?.split(" ");
  const timeFormat = dateFormat[1]?.split(":");
  const date = new Date(dateFormat[0]);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear()?.toString();
  let hours = timeFormat[0]; // gives the value in 24 hours format
  const AmOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  let minutes = timeFormat[1];
  let time =
    (hours < 10 ? `0${hours}` : hours) +
    ":" +
    (minutes < 10 ? `${minutes}` : minutes) +
    " " +
    AmOrPm;

  return fromTable ? (
    <div>
      {month} {day}, {parseInt(year)} {time}
    </div>
  ) : (
    `${month} ${day}, ${parseInt(year)} ${time}`
  );
};
