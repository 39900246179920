import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutlineRounded";

import { Chip } from "@my-scoot/component-library-legacy";
import dataProvider from "data/dataProvider";
import React from "react";
import { is_desktop } from "utils/Utils";
import { useStyles } from "./CustomColumns.styles";

import classnames from "classnames";

export const ValidityField = ({ record, showValidityPopup }) => {
  const classes = useStyles({ is_desktop });

  return record["applicability"] == 2 || record["applicability"] == 4 ? (
    <span
      className={classes.buttonLink}
      onClick={() => showValidityPopup(record.uuid)}
    >
      Specific Offerings
    </span>
  ) : (
    "All Offerings"
  );
};

export const CustomerEligibility = ({ record, showEligibilityPopup }) => {
  const classes = useStyles({ is_desktop });

  return record["applicability"] == 4 ? (
    <span
      className={classes.buttonLink}
      onClick={() => showEligibilityPopup(record.uuid)}
    >
      View Audience
    </span>
  ) : (
    <span>None</span>
  );
};

export const StatusField = ({ record }) => {
  const classes = useStyles();

  return (
    <Chip
      label={record.status}
      chipClassName={classnames(
        classes.chipClassName,
        record.status === "Active"
          ? classes.statusActive
          : classes.statusInActive
      )}
    />
  );
};

export const ActionField = ({
  record,
  form_fields,
  setSelectedRecordData,
  setShowDelete,
}) => {
  const classes = useStyles();

  return (
    <IconButton
      onClick={() => {
        try {
          dataProvider.postAnalyticalEvent("discountCodePage", "deleteCode", {
            flow: "discountCode",
            funnel: "discountCodePage",
            ui_component: "Text Button",
            identifier: form_fields.del_btn,
            booking_meta: JSON.stringify(record),
          });
          setSelectedRecordData(record);
          setShowDelete(true);
        } catch (e) {
          console.log(e);
        }
      }}
      className={classes.deleteIcon}
    >
      <DeleteIcon />
    </IconButton>
  );
};
