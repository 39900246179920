import React from "react";
import { Accordion } from "@my-scoot/component-library-legacy";
import FaqEditor from "./FaqEditor";
import { useRefresh } from "react-admin";
import { useStyles } from "./styles";
import { is_empty } from "utils/validations";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { labels } from "../../constants";

const ListItem = (props) => {
  const { record, openAccordion, handleClick, accordionId, styles } = props;
  const refetch = useRefresh();

  return (
    <Accordion
      className={styles.accordion}
      accordionSummaryContent={<span>{record.question}</span>}
      expandIconPosition="end"
      expanded={openAccordion === accordionId}
      onChange={handleClick}
      TransitionProps={{ unmountOnExit: true }}
    >
      {openAccordion === accordionId && (
        <FaqEditor
          refetch={(value) => {
            refetch(value);
          }}
          selectedRecordData={record}
          showDelete={true}
          {...props}
        />
      )}
    </Accordion>
  );
};

const FaqListing = ({ list, openAccordion, setOpenAccordion }) => {
  const isDesktop = useDesktopMediaQuery();
  const styles = useStyles(isDesktop);

  if (is_empty(list)) return null;

  // Categorizing and grouping FaqList
  const groupedFaqs = Object.keys(list).reduce((collection, key, index) => {
    const group = { ...collection };
    const currentItem = list[key];
    const handleAccordion = (panel) => (event, newExpanded) =>
      setOpenAccordion(newExpanded ? panel : false);
    const jsx = (
      <ListItem
        key={currentItem.uuid}
        record={currentItem}
        openAccordion={openAccordion}
        handleClick={handleAccordion(`exly_accordion_${index}`)}
        accordionId={`exly_accordion_${index}`}
        styles={styles}
        setOpenAccordion={setOpenAccordion}
      />
    );

    if (currentItem.listing__title in collection)
      group[currentItem.listing__title] = [
        ...group[currentItem.listing__title],
        jsx,
      ];
    else group[currentItem.listing__title] = [jsx];

    return group;
  }, {});

  // Group Title's and corresponding entries
  const _faqListing = Object.keys(groupedFaqs).reduce((elements, key) => {
    const categoryName = key === "null" ? labels.ALL_OFFERINGS : key;
    return [
      ...elements,
      <div key={categoryName} className={styles.faqGroupTitle}>
        {categoryName}
      </div>,
      <div
        key={categoryName + groupedFaqs[key].length}
        className={styles.faqGroupContent}
      >
        {groupedFaqs[key]}
      </div>,
    ];
  }, []);

  return _faqListing;
};

export default FaqListing;
