import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import useStyles from "./styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { FAQ_LEARN_MORE_HREFS } from "../../constants";

const BtnPrimary = ({ children, handleClick }) => {
  return (
    <Button color="primary" onClick={handleClick} fullWidth={true}>
      {children}
    </Button>
  );
};

const PageHead = (props) => {
  const { title, btnPrimary, primaryClick } = props;
  const isDesktop = useDesktopMediaQuery();
  const styles = useStyles(isDesktop);

  const bottomNav = (
    <div className={styles.bottomActions}>
      {btnPrimary && (
        <BtnPrimary handleClick={primaryClick}>{btnPrimary}</BtnPrimary>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.container}>
        {isDesktop && <span>{title}</span>}
        <div>
          {isDesktop && btnPrimary && (
            <div className="d-flex">
              <LearnMoreCta href={FAQ_LEARN_MORE_HREFS.FAQ} className="mr-3" />
              <BtnPrimary handleClick={primaryClick}>{btnPrimary}</BtnPrimary>
            </div>
          )}
          {!isDesktop && bottomNav}
        </div>
      </div>
    </>
  );
};

export default PageHead;
