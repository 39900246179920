import ExlyModal from "common/Components/ExlyModal";
import { isDeviceIOS } from "common/util";
import constants from "constants/constants";
import React from "react";
import { useRefresh } from "react-admin";
import { LimitedOfferModalContent } from "ui/modules/LimitedOfferModalContent";
import { useNotifications } from "utils/hooks";
import { is_desktop } from "utils/Utils";
import { useStyles } from "./index.styles";

const CreateOfferModal = ({
  open,
  onClose,
  gst_status,
  onCreateSuccess,
  refreshPageOnCreateSuccess = true,
  showShareModal = true,
  ...restProps
}) => {
  const classes = useStyles({ is_desktop });

  const [loading, setLoading] = React.useState(false);
  const child = React.useRef(null);

  const refresh = useRefresh();
  const { notify } = useNotifications();

  React.useEffect(() => {
    if (!isDeviceIOS() || is_desktop) return;

    let prevScrollPos = window.scrollY;
    document.body.style.marginTop = `${-window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.top = 0;
    document.body.style.bottom = 0;
    document.body.style.left = 0;
    document.body.style.right = 0;

    return () => {
      document.body.style.position = "static";
      document.body.style.top = "auto";
      document.body.style.bottom = "auto";
      document.body.style.left = "auto";
      document.body.style.right = "auto";
      document.body.style.marginTop = 0;
      window.scrollTo(0, prevScrollPos);
    };
  }, []);

  const handleSave = async () => {
    setLoading(true);
    if (child.current) {
      try {
        const { limitedOfferCreative } = await child.current.triggerSave();
        if (limitedOfferCreative || !showShareModal) {
          onClose();
          if (refreshPageOnCreateSuccess) refresh();
          if (onCreateSuccess) onCreateSuccess();
        }
      } catch (error) {
        notify(constants.error_message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ExlyModal
      modal_props={{
        modalPaperClassName: classes.modalPaperClassName,
      }}
      open={open}
      onClose={onClose}
      title="Create Limited-Time Offer"
      primaryBtnText="Create offer"
      onPrimaryBtnClick={handleSave}
      secondaryBtnText="Cancel"
      onSecondaryBtnClick={onClose}
      primaryBtnProps={{
        disabled: loading,
        loading,
      }}
      mobile_modal_props={{
        secondaryAction: {
          label: "Cancel",
          onClick: onClose,
        },
        footerClassName: classes.footerClassName,
      }}
      {...restProps}
    >
      <div className={classes.dialogContentClassName}>
        <LimitedOfferModalContent
          hideLayout
          ref={child}
          handleClick={onClose}
          gst_status={gst_status}
          showShareModal
        />
      </div>
    </ExlyModal>
  );
};

export default CreateOfferModal;
