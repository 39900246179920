import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    carouselWrap: {
      overflow: "hidden",
      background: theme.palette.primary.shade_100,
      margin: ({ isDesktop }) => (isDesktop ? "0 -24px" : "0 -16px"),
      width: ({ isDesktop }) =>
        isDesktop ? "calc(100% + 48px)" : "calc(100% + 32px)",
    },
    arrow: {
      position: "absolute",
      padding: 0,
      zIndex: 100,
      top: "50%",
    },
    prevArrow: {
      left: ({ isDesktop }) => (isDesktop ? "44ppx" : "20px"),
    },
    nextArrow: {
      right: ({ isDesktop }) => (isDesktop ? "44ppx" : "20px"),
    },
    slideWrap: {
      paddingTop: ({ isDesktop }) => (isDesktop ? "13px" : "16px"),
      wordBreak: "break-word",
    },
    exitPopup: {
      borderRadius: "5px",
      background: theme.palette.basic.white,
      padding: "20.7px 17.75px 14.79px 17.75px",
      margin: "16px auto 37px auto",
      width: "244px",
    },
    discountOffer: {
      color: theme.palette.primary.main,
      margin: "8px 0 8px 0",
      fontSize: "32px",
      fontWeight: 700,
    },
    description: {
      fontSize: "12px",
      fontWeight: 500,
      marginBottom: "12px",
    },
    expiryDescription: {
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "12px",
      marginTop: "8px",
    },
    discountPopup: {
      width: ({ isDesktop }) => isDesktop ? "430px" : "244px",
      fontSize: "14px",
      lineHeight: "17px",
    },
    carousel: {
      "& .slick-track": {
        display: "flex",
        alignItems: "center",
      },
    },
    discountCodeName: {
      border: `1.5px dashed ${theme.palette.basic.black}`,
      borderRadius: "5px",
      fontSize: "16px",
      lineHeight: "20px",
      padding: "14.83px 16.38px 10.31px 16.38px",
      display: "inline-block",
      marginBottom: "14.79px",
    },
    applyBtn: {
      height: "unset",
      fontSize: "16px",
      lineHeight: "16px",
      padding: "13px 8px",
    }
  }),
  { name: "CreatorTool" }
);

export default useStyles;
