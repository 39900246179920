import React, { useState, useEffect } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useDispatch } from "react-redux";
import {
  EXLY_GLOBAL__SET_PAGE_TITLE,
  EXLY_GLOBAL__SET_SHOW_APP_BAR,
  EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
} from "redux/actions";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import PageHead from "./components/pageHead/PageHead";
import PageBody from "./components/pageBody/PageBody";
import { constants, FAQ_LEARN_MORE_HREFS } from "./constants";
import useStyles from "./styles";
import useAppLayoutControl from "hooks/useAppLayoutControl";

const ExlyFAQ = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const css = useStyles(isDesktop);
  const dispatch = useDispatch();

  const [showUpload, setShowUpload] = useState(false);
  const [openAccordion, setOpenAccordion] = useState("");

  useEffect(() => {
    dispatch({ type: EXLY_GLOBAL__SET_PAGE_TITLE, payload: "FAQs" });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: isDesktop });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: false });

    return () => {
      dispatch({ type: EXLY_GLOBAL__SET_PAGE_TITLE, payload: "" });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: true });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
    };
  }, [dispatch, isDesktop]);

  useEffect(() => {
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: false });

    return () => {
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
    };
  }, []);

  useAppLayoutControl({ values: { learnMoreHref: FAQ_LEARN_MORE_HREFS.FAQ } });

  const commonButtonProps = {
    btnPrimary: constants.label,
    primaryClick: () => {
      setOpenAccordion("");
      setShowUpload(true);
    },
  };

  return (
    <div className={`component-wrapper-x ${css.contentContainer}`}>
      <PageHead
        title={constants.title}
        btnLink={constants.label}
        {...commonButtonProps}
      />
      <PageBody
        {...props}
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        openAccordion={openAccordion}
        setOpenAccordion={setOpenAccordion}
      />
    </div>
  );
};

export default withComponentLibraryTheme(ExlyFAQ);
