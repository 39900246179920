import React from "react";
import useStyles from "./styles";
import { Button } from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const Fallback = (props) => {
  const { title, img, titleSecondary, btnTxt, handleBtn } = props;
  const isDesktop = useDesktopMediaQuery();
  const css = useStyles(isDesktop);

  return (
    <>
      <div className={css.fallback}>
        <img src={img} alt="No Data" />
        <p>{title}</p>
        <span>{titleSecondary}</span>
        {btnTxt && handleBtn && (
          <Button size="medium" color="primary" onClick={handleBtn}>
            {btnTxt}
          </Button>
        )}
      </div>
    </>
  );
};

export default Fallback;
