import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import CloseIcon from "@material-ui/icons/CloseRounded";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import api from "data/APIs";
import dataProvider from "data/dataProvider";
import * as React from "react";
import { is_empty } from "utils/validations";

const useStyles = makeStyles((theme) => ({
  discountInfoRoot: {
    paddingLeft: "24px",
    "& th, td": {
      fontSize: "16px",
      padding: "10px 24px 10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  modalRoot: {
    minWidth: "560px",
    minHeight: "259px",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: 700,
    "& span": {
      marginLeft: "12.25px",
    },
    display: "flex",
    alignItems: "center",
  },
  customHeaderRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "142.46px",
    padding: "16px 29.93px 16px 24.49px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    boxSizing: "border-box",
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const DiscountInfoModal = ({ showModal, onClose, discountId }) => {
  const classes = useStyles();
  const [discountInfo, setDiscountInfo] = React.useState({});
  const isDesktop = window.screen.width > constants.mobile_width;

  React.useEffect(() => {
    (async () => {
      if (!discountId) return;
      const data = await dataProvider.custom_request(
        api.fetch_discount_listings,
        "GET",
        {
          discount_uuid: discountId,
          is_listing_discount: true,
        }
      );

      if (!is_empty(data.data)) {
        setDiscountInfo(data.data);
      }
    })();

    return () => {
      setDiscountInfo({});
    };
  }, [discountId]);

  const DiscountInfo = (
    <div className={classes.discountInfoRoot}>
      <TableContainer>
        <Table aria-label="Discout offering">
          <TableHead>
            <TableRow>
              <TableCell>Offering name</TableCell>
              <TableCell align="right">No. of times used</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discountInfo &&
              discountInfo?.listings?.map((listing) => (
                <TableRow key={listing.uuid}>
                  <TableCell>{listing.listing_name}</TableCell>
                  <TableCell align="right">
                    {listing.number_of_times_used}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.modalRoot}
      open={showModal}
      onClose={onClose}
      customHeader={
        <div className={classes.customHeaderRoot}>
          <span className={classes.modalTitle}>
            This coupon code can be applied to the following offerings:
          </span>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </div>
      }
      customFooter={<></>}
    >
      {DiscountInfo}
    </Modal>
  ) : (
    <MobileModal
      open={showModal}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      header={
        <div>
          <span className={classes.modalTitle}>
            This coupon code can be applied to the following offerings:
          </span>
        </div>
      }
      customFooter={<></>}
    >
      {DiscountInfo}
    </MobileModal>
  );
};

export default DiscountInfoModal;
