import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  faqGroupTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#272522",
    textTransform: "capitalize",
    marginBottom: "16px",
  },
  accordion: {
    border: "1.5px solid #E7E6E4",
    boxShadow: "0px 3px 0px rgba(14, 13, 12, 0.14)",
    borderRadius: "12px",
    marginBottom: "16px",
    "& > div:first-child": {
      minHeight: "48px",
      height: "48px",
      maxHeight: "60px",
    },
    "& > div:first-child > div:first-child": {
      margin: "8px 16px 8px 16px",
      fontSize: ({ isDesktop }) => (isDesktop ? "16px" : "14px"),
    },
    "& > div:first-child > div:last-child": {
      margin: "15px 20px 16px 16px",
    },
  },
  faqGroupContent: {
    "& > div:last-child": {
      marginBottom: "24px!important",
    },
  },
  faqEditorBody: {
    padding: ({ isDesktop, isModal }) => {
      if (isDesktop && !isModal) return "16px 32px 24px";
      if (isDesktop && isModal) return "16px 24px 0px";
      if (!isDesktop && !isModal) return "16px 14px 20px";
      if (!isDesktop && isModal) return "16px 16px 0px";
    },
    "& > div > div:not(:last-child)": {
      marginBottom: "24px",
      // Labels
      "& > div:first-child": {
        marginBottom: "12px",
        fontWeight: "500",
      },
    },
    "& > div > div:nth-of-type(2) > div:last-child div": {
      height: "32px",
      fontSize: "14px",
    },
    "& > div > div:nth-of-type(1) > div:last-child": {
      padding: ({ isDesktop }) => (isDesktop ? "5px 12px" : "5px 6px 5px 12px"),
      fontSize: ({ isDesktop }) => !isDesktop && "5px 6px 5px 12px",
    },
  },
  faqResizable: {
    "& > div": {
      resize: "vertical",
      overflow: "hidden",
    },
  },
  actionButtons: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    overflow: "hidden",
    "& > button": {
      padding: "8px",
      marginRight: "47px",
      color: "#BF2600",
    },
    "&": {
      position: ({ isModal }) => isModal && "relative",
      left: ({ isModal }) => isModal && "-24px",
      padding: ({ isDesktop, isModal }) =>
        isDesktop
          ? isModal && "18px 24px 18px 24px"
          : isModal && "18px 13px 18px 24px",
      borderTop: ({ isModal }) => isModal && "1.5px solid #E7E6E4",
      width: ({ isModal }) => isModal && "calc(100% + 48px)",
      maxWidth: ({ isDesktop, isModal }) => !isDesktop && isModal && "102vw",
    },
  },
  loader: {
    backgroundColor: "#ffffff",
    padding: 0,
    overflow: "visible",
    width: "100%",
  },
  label: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#272522",
    marginBottom: "12px",
  },
}));
