import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import EmptyListIcon from "assets/vectors/infoModal/search.svg";
import useStyles from "./styles";

const NoResults = () => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  return (
    <div className={classes.wrapper}>
      <img src={EmptyListIcon} alt="empty-icon" />
      <div className={classes.title}>No results found</div>
      <div className={classes.description}>Try different keywords</div>
    </div>
  );
};

export default NoResults;
