import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    container: {
      position: "sticky",
      top: "48px",
      zIndex: "100",
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      padding: (isDesktop) => (isDesktop ? "16px 0" : "16px 0 0 0"),
      "& > span": {
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "22px",
        letterSpacing: "0.04em",
        color: "#272522",
      },
      "& > div": {
        display: "flex",
        flexDirection: "row",
        "& > div:first-child button": {
          whiteSpace: "nowrap",
        },
      },
    },
    bottomActions: {
      position: "fixed",
      bottom: "0",
      left: "0",
      height: "68px",
      backgroundColor: "#fff",
      width: "100vw",
      zIndex: "2",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "2px solid #E7E6E4",
      padding: "16px 12px",
      "& > div:first-child button": {
        padding: "0",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
