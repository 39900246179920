import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Modal as MuiModal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { downloadCSV } from "react-admin";
import LimitedOfferShare from "../../modules/LimitedOfferTimeShare";
import style from "./Style.module.css";
import dataProvider from "../../../data/dataProvider";
import { ConfirmationPopup } from "./ConfirmationPopup";
import { useGSTStatus } from "../../../utils/hooks";
import {
  columnConfig,
  filterConfig,
  tableChipsFormatter,
} from "./limitedOfferTableConfig";
import ExlyTable from "common/Components/ExlyTable";
import { useStyles } from "./limitedOffer.styles";
import { Button, Modal } from "@my-scoot/component-library-legacy";
import {
  MoreActionsButton,
  PrimaryActionField,
  DateField,
  getStatus,
  getCurrencySymbol,
  StatusField,
} from "./components/CustomColumns";
import LearnMoreModal from "../../widgets/LearnMoreModal/LearnMoreModal";
import jsonExport from "jsonexport/dist";
import { orgPermissions, RESOURCE_KEYS } from "../../../utils/OrgPermissions";
import { api } from "data";
import CreateOfferModal from "./modals/CreateOfferModal/CreateOfferModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import {
  LIMITED_TIME_OFFER_LEARN_MORE_HREF,
  moreActionKeys,
} from "./constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

// TODO: Empty State UI Pending
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No offers found!</div>
    </div>
  );
};

export const LimitedOffer = withComponentLibraryTheme((props) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const [showModal, setShowModal] = useState(false);
  const [offerModal, setOffermodal] = useState(false);
  const [learnMore, setLearnmore] = useState(false);
  const [dataRecord, setdataRecord] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationTitle, setconfirmationTitle] = useState(false);
  const [moreData, setmoreData] = useState("");
  const [currentSelectedRecord, setCurrentSelectedRecord] = useState(null);
  const gst_status = useGSTStatus();
  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.LIMITED_TIME_OFFER
  );

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.LTO,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const options = [
    {
      key: 0,
      data: HAS_WRITE_ACCESS
        ? [
            moreActionKeys.edit,
            moreActionKeys.share,
            moreActionKeys.delete,
            moreActionKeys.viewTransactions,
          ]
        : [moreActionKeys.share, moreActionKeys.viewTransactions],
    },
    {
      key: 1,
      data: HAS_WRITE_ACCESS
        ? [
            moreActionKeys.edit,
            moreActionKeys.share,
            moreActionKeys.endOffer,
            moreActionKeys.viewTransactions,
          ]
        : [moreActionKeys.share, moreActionKeys.viewTransactions],
    },
    {
      key: 2,
      data: [moreActionKeys.viewTransactions],
    },
  ];

  const handleClick = () => {
    setShowModal(!showModal);
    setOffermodal(!offerModal);
    if (window.sessionStorage.getItem("editData")) {
      window.sessionStorage.removeItem("editData");
    }
  };

  const handleDelete = async () => {
    try {
      const record = dataRecord;
      const payload = {
        offer_uid: record.uuid,
      };
      const deleteData = await dataProvider.custom_request(
        "host/offer/delete",
        "POST",
        payload
      );
      if (deleteData.status === 200) {
        alert("deleted successfully!");
      }
      window.location.reload();
    } catch (err) {
      alert(err?.body?.message);
    }
  };

  const handleEndOffer = async () => {
    try {
      const record = dataRecord;
      const payload = {
        offer_uid: record.uuid,
      };
      const expireOffer = await dataProvider.custom_request(
        "host/offer/expire",
        "POST",
        payload
      );
      if (expireOffer.status === 200) {
        alert("offer expired successfully!");
      }
      window.location.reload();
    } catch (err) {
      alert(err?.body?.message);
    }
  };

  const handleItemClick = async (itemData, record) => {
    if (itemData === "Share") {
      setIsModal(true);
    } else if (itemData === "Edit") {
      handleClick();
      window.sessionStorage.setItem("editData", JSON.stringify(record));
    } else if (itemData === "Delete") {
      setConfirmationModal(true);
      setconfirmationTitle(
        "Are you sure you want to delete this active discount? Doing so will change the price to the original price."
      );
    } else if (itemData === "End Offer") {
      setConfirmationModal(true);
      setconfirmationTitle(
        "Are you sure?, Once you end the offer, your customers will not be able to avail the discount."
      );
    } else if (itemData === "View Transactions") {
      window.location.href = `${window.location.origin}/#/${api.transaction_list}?feature_discount_info__early_bird_id=${record.uuid}`;
    }
  };

  const secondaryComponent = (
    <div className={classes.buttonsContainer}>
      {HAS_WRITE_ACCESS && (
        <div className="d-flex">
          <LearnMoreCta
            href={LIMITED_TIME_OFFER_LEARN_MORE_HREF.LTO}
            className="mr-3"
          />
          <Button
            className={classes.buttonClassName}
            color="primary"
            onClick={handleClick}
          >
            Create Limited-Time Offer
          </Button>
        </div>
      )}
    </div>
  );

  const exporter = (data) => {
    dataProvider.postAnalyticalEvent("limitedOffer", "export", {
      flow: "limitedOffer",
      funnel: "limitedOffer",
      ui_component: "Text Button",
      identifier: "EXPORT",
    });
    const dataForExport = data.map((data) => {
      let return_data = {
        "Offer Name": data.title,
        "Offering Name": data.listing_name,
        "Discount value": `${getCurrencySymbol(data)} ${data.price}`,
        "Total discount given":
          (data.total_discount &&
            `${getCurrencySymbol(data)} ${data.total_discount}`) ||
          `N/A`,
        "No. of bookings": data.bookings || `N/A`,
        "Discount end date": DateField({
          record: data,
          source: "end_datetime",
        }),
        Status: getStatus(data),
      };
      return return_data;
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "Offer Name",
          "Offering Name",
          "Discount value",
          "Total discount given",
          "No. of bookings",
          // "Discount end date",
          // "Status",
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Limited Offer"); // download as 'posts.csv` file
      }
    );
  };

  const footerProps = {
    primarybtn: "Create Limited-Time Offer",
    primaryClick: () => handleClick(),
    hidePrimarybtn: !HAS_WRITE_ACCESS,
  };

  const getMoreActions = (record) => {
    if (!record) return;

    const option = options.find((option) => option.key === record?.status);
    return option.data
      .map((action) => ({
        label:
          action === "View Transactions" && record.bookings
            ? action
            : action !== "View Transactions"
            ? action
            : null,
        onClick: () => {
          setdataRecord(record);
          handleItemClick(action, record);
          setmoreData(action);
        },
      }))
      .filter((option) => option.label);
  };

  return (
    <div className="component-wrapper">
      <ExlyTable
        tableTitle="Limited-Time Offers"
        ra_props={{
          ...props,
          exporter: orgPermissions.canExport() ? exporter : false,
        }}
        noExportButton={!orgPermissions.canExport()}
        columnConfig={columnConfig(classes)}
        tableFilters={filterConfig}
        layoutProps={{
          secondaryComponent: isDesktop ? secondaryComponent : null,
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "120px",
          noMobileBoxShadow: true,
          learnMoreHref: LIMITED_TIME_OFFER_LEARN_MORE_HREF.LTO,
        }}
        filteredEmptyState={<FilteredEmptyState />}
        tableChipsFormatter={tableChipsFormatter}
        primaryKey="id"
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        footerProps={footerProps}
        hasMobileFooter={HAS_WRITE_ACCESS}
        renderSecondaryAction={(record) => <StatusField record={record} />}
        customPrimaryAction={{
          renderCustomAction: (
            record,
            rows,
            setDrawerConfig,
            setActionsDrawerConfig
          ) =>
            record.status !== 2 && (
              <MoreActionsButton
                label="More Actions"
                onClick={() => {
                  setCurrentSelectedRecord(record);
                  setActionsDrawerConfig({ row: record, open: true });
                }}
              />
            ),
          onClose: (row, rows, setDrawerConfig, setActionsDrawerConfig) => {
            setActionsDrawerConfig((prevState) => ({
              ...prevState,
              open: false,
            }));
          },
        }}
        recordFooterClassName={classes.recordFooterClassName}
        primaryActions={getMoreActions(currentSelectedRecord)}
        desktopCustomPrimaryAction={{
          renderCustomAction: (row, rows, setAnchorEl, setOpenPopper) =>
            row.status !== 2 && (
              <PrimaryActionField
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setOpenPopper(true);
                  setCurrentSelectedRecord(row);
                }}
              />
            ),
        }}
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "No limited time offer yet",
          description:
            "As soon as we have a new limited time offer, it will show up here. View the video to learn more.",
          videoSrc: LIMITED_TIME_OFFER_LEARN_MORE_HREF.LTO_VIDEO,
          playerProps: {
            playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
          },
          primaryCtaProps: HAS_WRITE_ACCESS && {
            title: "Create Limited-Time Offer",
            onClick: handleClick,
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: LIMITED_TIME_OFFER_LEARN_MORE_HREF.LTO,
          },
          className: "mt-3",
        }}
      />

      {showModal && (
        <CreateOfferModal
          open={showModal}
          onClose={handleClick}
          gst_status={gst_status}
        />
      )}
      <LearnMoreModal
        title="How To Use Limited-Time Offers"
        list={[
          "Create discounts for your classes/workshops/content",
          "Set a limited time for which the offer will be valid",
          "Offer to be availed by all customers or students",
        ]}
        open={learnMore}
        icon={require("../../../assets/images/Create_limited_time_offers.svg")}
        onModalClose={() => setLearnmore(false)}
        onClose={() => {
          setLearnmore(false);
          setShowModal(false);
        }}
      />

      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        customHeader={<></>}
        customFooter={<></>}
      >
        <LimitedOfferShare
          setShowModal={isModal}
          uid={dataRecord?.listing_uuid}
          listing={dataRecord}
        />
      </Modal>

      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={confirmationModal}
        onClose={() => {
          setConfirmationModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={`alert_modal ${style.confirmationalert}`}>
          <ConfirmationPopup
            handleEndOffer={handleEndOffer}
            title={confirmationTitle}
            action={moreData}
            handleDelete={handleDelete}
            setConfirmationModal={setConfirmationModal}
          />
        </div>
      </MuiModal>
    </div>
  );
});
