import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  () => ({
    modalPaperClassName: {
      minWidth: "48%",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
