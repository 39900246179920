import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    desktopModalPaper: {
      width: "677px",
      maxWidth: "677px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
