import { makeStyles } from "@material-ui/core/styles";

import ExlyModal from "common/Components/ExlyModal";
import { isDeviceIOS } from "common/util";
import React from "react";
import { useRefresh } from "react-admin";
import { is_desktop } from "utils/Utils";
import CreateDiscounts from "../../CreateDiscounts";

const useStyles = makeStyles(() => ({
  modalPaperClassName: {
    minWidth: "50%",
  },
  dialogContentClassName: {
    padding: ({ is_desktop }) => (is_desktop ? "24px" : "16px"),
  },
}));

const CreateDiscountModal = ({
  open,
  onClose,
  setSelectedDiscountCode,
  ...restProps
}) => {
  const classes = useStyles({ is_desktop });

  const child = React.useRef();
  const refresh = useRefresh();

  const CreateDiscountWrapper = ({
    children,
    submitting,
    loading,
    LoadingState,
  }) => {
    return (
      <ExlyModal
        title="Create new discount code"
        open={open}
        onClose={onClose}
        modal_props={{ modalPaperClassName: classes.modalPaperClassName }}
        className={classes.dialogContentClassName}
        showCross
        primaryBtnText="Create discount code"
        onPrimaryBtnClick={handleSave}
        secondaryBtnText="Cancel"
        onSecondaryBtnClick={onClose}
        mobile_modal_props={{
          secondaryAction: {
            label: "Cancel",
            onClick: onClose,
          },
        }}
        primaryBtnProps={{
          loading: submitting,
          disabled: submitting,
        }}
        {...restProps}
      >
        {loading ? <LoadingState /> : children}
      </ExlyModal>
    );
  };

  React.useEffect(() => {
    if (!isDeviceIOS() || is_desktop) return;

    let prevScrollPos = window.scrollY;
    document.body.style.marginTop = `${-window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.top = 0;
    document.body.style.bottom = 0;
    document.body.style.left = 0;
    document.body.style.right = 0;

    return () => {
      document.body.style.position = "static";
      document.body.style.top = "auto";
      document.body.style.bottom = "auto";
      document.body.style.left = "auto";
      document.body.style.right = "auto";
      document.body.style.marginTop = 0;
      window.scrollTo(0, prevScrollPos);
    };
  }, []);

  const handleSave = async () => {
    if (child.current) child.current.triggerSave();
  };

  return (
    <CreateDiscounts
      listingMultiSelect={true}
      ref={child}
      onSubmit={() => {
        onClose();
        refresh();
      }}
      onClose={onClose}
      setSelectedDiscountCode={setSelectedDiscountCode}
      component={CreateDiscountWrapper}
    />
  );
};

export default CreateDiscountModal;
