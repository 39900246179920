import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  statusExpired: {
    height: "22px",
    background: `${theme?.palette?.basic?.danger_red} !important`,
    color: `${theme?.palette?.basic?.white} !important`,
    pointerEvents: "none",
  },
  statusActive: {
    height: "22px",
    background: `${theme?.palette?.fine_pine?.main} !important`,
    color: `${theme?.palette?.basic?.white} !important`,
    pointerEvents: "none",
  },
  statusScheduled: {
    height: "22px",
    background: `${theme?.palette?.butterscotch_yellow?.main} !important`,
    color: `${theme?.palette?.basic?.white} !important`,
    pointerEvents: "none",
    "& img": {
      marginRight: "-2px",
    },
  },
  actionButton: {
    color: theme?.palette?.primary?.main,
  },
  seeMoreBtn: {
    height: "17px",
    background: "inherit",
    textDecoration: "underline",
    padding: "0",
    "&:hover": {
      background: "inherit",
    },
    color: theme?.palette?.primary?.main,
    "& svg": {
      color: theme?.palette?.primary?.main,
    },
  },
}));
