import React from "react";
import {
  DateTimeField,
  MoneyField,
  StatusField,
} from "./components/CustomColumns";

export const columnConfig = (classes) => [
  {
    field: "title",
    headerName: "Offer name",
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
    ellipsis: true,
    maxCharCount: 30,
  },
  {
    field: "listing_name",
    headerName: "Offering name",
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
    ellipsis: true,
    maxCharCount: 30,
  },
  {
    field: "price",
    headerName: "Offer Price",
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
    valueFormatter: (record) => <MoneyField noRightMargin record={record} source="price" />,
  },
  {
    field: "bookings",
    headerName: "No. of bookings",
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
  },
  {
    field: "total_discount",
    headerName: "Total discount given",
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <MoneyField record={record} source="total_discount" noRightMargin />
    ),
  },
  {
    field: "end_datetime",
    headerName: "End date",
    emptyField: "N/A",
    noWrap: true,
    sortable: false,
    valueFormatter: (record) => (
      <DateTimeField record={record} source="end_datetime" />
    ),
    columnClassName: classes.endDate,
  },
  {
    field: "status",
    headerName: "",
    valueFormatter: (record) => <StatusField record={record} />,
    align: "right",
    hidden: true,
  },
];

export const filterConfig = [
  {
    source: "title",
    placeholder: "Offer Name",
    type: "input",
  },
  {
    source: "listing__title",
    placeholder: "Offering Name",
    type: "input",
  },
  {
    source: "status",
    placeholder: "All Status",
    type: "dropdown",
    options: [
      { label: "Active", value: 1 },
      { label: "Scheduled", value: 0 },
      { label: "Expired", value: 2 },
    ],
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "title__icontains":
      return `Offer Name: ${value}`;
    case "listing__title__icontains":
      return `Offering Name: ${value}`;
    case "status":
      return `Status: ${value}`;
    default:
      return undefined;
  }
};
