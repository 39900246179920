import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import CloseIcon from "@material-ui/icons/CloseRounded";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import api from "data/APIs";
import dataProvider from "data/dataProvider";
import { is_empty } from "utils/validations";
import { useNotifications } from "utils/hooks";

const useStyles = makeStyles((theme) => ({
  eligibilityInfo: {
    paddingLeft: "24px",
    "& td": {
      fontSize: "14px",
      padding: "10px 24px 10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  specificOfferingsModalPaperClassName: {
    minWidth: "560px",
    minHeight: "259px",
  },
  specificOfferingsModalTitle: {
    fontSize: "18px",
    fontWeight: 700,
    "& span": {
      marginLeft: "12.25px",
    },
    display: "flex",
    alignItems: "center",
  },
  specificOfferingsRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "142.46px",
    padding: "16px 29.93px 16px 24.49px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    boxSizing: "border-box",
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const EligibilityInfoModal = ({
  showModal,
  onClose,
  onClickClose,
  eligibilityId,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotifications();
  const [eligibilityInfo, setEligibilityInfo] = React.useState({});
  const isDesktop = window.screen.width > constants.mobile_width;

  React.useEffect(() => {
    (async () => {
      if (!eligibilityId) return;
      try {
        const data = await dataProvider.custom_request(
          api.get_post_audiance_list,
          "GET",
          {
            discount_uid: eligibilityId,
          }
        );

        if (!is_empty(data.data)) {
          setEligibilityInfo(data.data);
        }
      } catch (e) {
        console.log(e);
        pushNotification("Unable to fetch eligibility info!", { variant: "outlined", color: "coral_red" });
      }
    })();

    return () => {
      setEligibilityInfo({});
    };
  }, [eligibilityId]);

  const EligibilityInfo = (
    <div className={classes.eligibilityInfo}>
      <TableContainer>
        <Table aria-label="Discout offering">
          <TableBody>
            {eligibilityInfo &&
              eligibilityInfo.audiences &&
              eligibilityInfo.audiences[0] &&
              eligibilityInfo.audiences[0]?.audience.groups.map((group, id) => (
                <TableRow key={`group-${id}`}>
                  <TableCell>{group}</TableCell>
                </TableRow>
              ))}

            {eligibilityInfo &&
              eligibilityInfo.audiences &&
              eligibilityInfo.audiences[0] &&
              eligibilityInfo.audiences[0]?.audience.extras.map((extra, id) => (
                <TableRow key={`extra-${id}`}>
                  <TableCell>{extra}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.specificOfferingsModalPaperClassName}
      open={showModal}
      onClose={onClose}
      customHeader={
        <div className={classes.specificOfferingsRoot}>
          <span className={classes.specificOfferingsModalTitle}>
            Customers Eligibility
          </span>
          <CloseIcon className={classes.closeIcon} onClick={onClickClose} />
        </div>
      }
      customFooter={<></>}
    >
      {EligibilityInfo}
    </Modal>
  ) : (
    <MobileModal
      open={showModal}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      header={
        <div>
          <span className={classes.specificOfferingsModalTitle}>
            Customers Eligibility
          </span>
        </div>
      }
      customFooter={<></>}
    >
      {EligibilityInfo}
    </MobileModal>
  );
};

export default EligibilityInfoModal;
