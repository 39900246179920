import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    couponFormWrap: {
      display: "flex",
      flexDirection: "column",
      gap: ({ isDesktop }) => (isDesktop ? "16px" : "20px"),
      paddingBottom: ({ isDesktop }) => isDesktop ? "29px" : "16px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
