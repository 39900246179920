import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles((theme) => ({
  paginationWrapper: {
    width: "100%",
    background: "white",
    zIndex: 100,
    bottom: "75.5px !important",
  },
  desktopPaginationClassName: {
    float: "right",
    marginRight: "16px !important",
    marginBottom: "23px !important",
    marginTop: "12px !important",
  },
  validity: {
    fontSize: "14px",
  },
  deleteIcon: {
    color: theme?.palette?.basic?.danger_red,
  },
  modalPaperClassName: {
    minWidth: "532px",
  },
  specificOfferingsModalPaperClassName: {
    minWidth: "560px",
    minHeight: "259px",
  },
  specificOfferingsModalTitle: {
    fontSize: "18px",
    fontWeight: 700,
    "& span": {
      marginLeft: "12.25px",
    },
    display: "flex",
    alignItems: "center",
  },
  specificOfferingsRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "142.46px",
    padding: "16px 29.93px 16px 24.49px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    boxSizing: "border-box",
  },
  warningIcon: {
    color: theme?.palette?.basic?.danger_red,
  },
  infoIcon: {
    fill: theme?.palette?.secondary?.main,
    width: '20px',
    height: '20px',
  },
  closeIcon: {
    cursor: "pointer",
  },
  desktopModalHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10.47px",
    height: "22px",
  },
  mobileModalHeader: {
    display: "flex",
    alignItems: "center",
    gap: "12.94px",
    height: "22px",
  },
  desktopWarningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "9.81px",
    padding: "15.12px 70px 88px 24px",
    "& svg": {
      fontSize: "97.38px",
      color: theme?.palette?.basic?.danger_red,
    },
  },
  mobileWarningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.81px",
    padding: "32.5px 62px 71px 16px",
    "& svg": {
      fontSize: "97.38px",
      color: theme?.palette?.basic?.danger_red,
    },
  },
  warningDescription: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 500,
    width: "100%",
  },
  modalRootClassName: {},
  discountCode: {
    fontWeight: 700,
  },
  iconButton: {
    padding: 0,
  },
  createDiscountCodeButton: {
    height: "42px",
  },
  discountInfoRoot: {
    paddingLeft: "24px",
    "& th, td": {
      fontSize: "16px",
      padding: "10px 24px 10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  eligibilityInfo: {
    paddingLeft: "24px",
    "& td": {
      fontSize: "14px",
      padding: "10px 24px 10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  titleContainerRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 25px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  buttonClassName: {
    padding: "11px 20px",
  },
  learnMoreButton: {
    textDecoration: "underline",
    color: `${theme?.palette?.primary?.main} !important`,
  },
  paddedTitle: {
    padding: "0 16px",
  },
  listItem: {
    padding: "0 0 0 16px",
    "& li": {
      marginTop: "16px",
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
      lineHeight: "19.09px"
    },
  },
  emptyListCardRoot: {
    maxWidth: "538px",
    padding: ({ isDesktop }) => !isDesktop && "31px",
    marginLeft: ({ isDesktop }) => isDesktop && "99.5px",
    "& ul": {
      padding: 0,
      paddingLeft: ({ isDesktop }) => isDesktop && "16px",
    },
  },
  emptyListCardTitle: {
    display: "block",
    fontSize: "24px",
    fontWeight: 700,
    marginTop: "24.84px",
  },
  primaryBtnClassName: {
    backgroundColor: `${theme?.palette?.basic?.danger_red} !important`,
  },
  customerTitle: {
    fontSize: "14px",
    fontWeight: 400,
    color: theme?.palette?.basic?.black,
    opacity: 0.85,
  },
  autoCompleteItem: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "8px 0",
    marginLeft: "11px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
  },
  applyBtn: {
    position: "fixed",
    bottom: "4px",
    left: 0,
    right: 0,
  },
  confirmGST: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
    fontWeight: 500,
    color: theme?.palette?.secondary?.main,
    "& label": {
      margin: 0,
    },
  },
  customerEligibility: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
    color: theme?.palette?.secondary?.main,
    "& label": {
      margin: 0,
    },
  },
  customerEligibilityHeading: {
    fontSize: "16px !important",
    color: theme?.palette?.secondary?.main,
  },
  customerEligibilityOptions: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  infoBanner: {
    padding: "8px 12px",
    borderRadius: "5px",
    background: theme?.palette?.primary?.shade_100,
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& svg": {
      color: theme?.palette?.primary?.main,
      fontSize: "19.21px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  inputLabel: {
    fontWeight: 500,
  },
  loading: {
    textAlign: "center",
  },
}));
