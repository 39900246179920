import React from "react";
import EmptyFaq from "./../../../../assets/vectors/empty_faq.svg";
import globalConstants from "constants/constants";
import { ReactComponent as ListingsIcon } from "assets/vectors/sidebar/listings.svg";

export const labels = {
  OFFERING_NAME: "Offering Name",
  SKU_TYPE: "SKU Type",
  ALL_OFFERINGS: "All Offerings",
  ADD_FAQS: "Add FAQs",
  SAVE_FAQ: "Save",
};

export const constants = {
  filterConfig: {
    categoryOptions: [
      { id: "1", label: labels.OFFERING_NAME, value: "1" },
      { id: "2", label: labels.SKU_TYPE, value: "2" },
    ],
    specificOptionsConfig: [
      "",
      [
        {
          id: "1",
          label: "One-on-One appointment",
          value: "1",
        },
        {
          id: "2",
          label: "Group Class",
          value: "2",
        },
        {
          id: "3",
          label: "Workshop",
          value: "3",
        },
        {
          id: "4",
          label: "Quick Payment Page",
          value: "4",
        },
        {
          id: "5",
          label: "Content: Videos/ Files",
          value: "5",
        },
        {
          id: "6",
          label: "Merchandise",
          value: "6",
        },
        {
          id: "7",
          label: "Flexible Class",
          value: "7",
        },
      ],
    ],
  },
  skyTypeIds: {
    "One-on-One appointment": 1,
    "Group Class": 2,
    Workshop: 3,
    "Quick Payment Page": 4,
    "Content: Videos/ Files": 5,
    Merchandise: 6,
    "Flexible Class": 7,
  },
  noData: {
    title: "No FAQs Yet .",
    img: EmptyFaq,
    titleSecondary:
      "They cover the basic questions customers tend to ask about your particular products, services, company, brand, and policies",
    btnTxt: "Add FAQs",
  },
  title: "FAQs",
  label: "Add FAQs",
  all_offering_option: {
    uuid: labels.ALL_OFFERINGS,
    title: labels.ALL_OFFERINGS,
    type: null,
    hide_description: true,
    value: labels.ALL_OFFERINGS,
    cover_icon: <ListingsIcon />,
  },
};

export const form_data = {
  start_date: "Start Date",
  end_date: "End Date",
  listing_name: "Offering Name",
  mode: "Mode",
  status: "Status",
  type: "Type",
  clear: "Clear Filter",
  customer_cta_header: "Customers List",
  link: "Meeting Link/Location",
  customer_count: "No. of customers",
  staff: "Staff",
};

const typeOptions = globalConstants.schedule_type.map((item) => ({
  ...item,
  label: item.name,
  value: item.id,
}));

export const modeOptions = [
  { label: "Offline", id: "Offline", value: "Offline" },
  { label: "Online", id: "Online", value: "Online" },
];

export const filterConfig = () => [
  {
    source: "listing__title",
    placeholder: form_data.listing_name,
    type: "input",
  },
  {
    source: "listing__type",
    placeholder: form_data.type,
    options: typeOptions,
    type: "dropdown",
    disableContains: true,
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "listing__title__icontains":
      return `Offering Name: ${value}`;
    case "listing__type": {
      const listing__type = typeOptions?.find((type) => type.id === value);
      return `Type: ${listing__type?.label}`;
    }
    default:
      return undefined;
  }
};

export const FAQ_LEARN_MORE_HREFS = {
  FAQ: "https://support.exlyapp.com/en/support/solutions/articles/84000347702-setup-faqs-on-exly",
};
