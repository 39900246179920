import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialogContentClassName: {
    padding: ({ is_desktop }) => (is_desktop ? "24px 0" : "16px 0"),
  },
  modalPaperClassName: {
    position: "static",
  },
  footerClassName: {
    zIndex: 7,
  },
}));
