export const moreActionKeys = {
  share: "Share",
  edit: "Edit",
  delete: "Delete",
  viewTransactions: "View Transactions",
  endOffer: "End Offer",
};

export const limited_time_offer_statuses = {
  scheduled: 0,
  active: 1,
  expired: 2,
};

export const limited_time_offer_statuses_data = {
  [limited_time_offer_statuses.scheduled]: {
    label: "Scheduled",
  },
  [limited_time_offer_statuses.active]: {
    label: "Active",
  },
  [limited_time_offer_statuses.expired]: {
    label: "Expired",
  },
};

export const LIMITED_TIME_OFFER_LEARN_MORE_HREF = {
  LTO: "https://support.exlyapp.com/support/solutions/articles/84000347854-limited-time-offers-on-exly",
  LTO_VIDEO: "https://www.youtube-nocookie.com/embed/fz_X0fqS1KQ",
};
