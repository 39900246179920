import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonLink: {
    fontSize: "14px !important",
    color: theme?.palette?.primary?.main,
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      textDecoration: ({ is_desktop }) => is_desktop && "underline",
    },
  },
  chipClassName: {
    marginRight: ({ is_desktop }) => !is_desktop && "8.3px",
  },
  statusInActive: {
    background: `${theme?.palette?.basic?.danger_red} !important`,
    color: `${theme?.palette?.basic?.white} !important`,
    pointerEvents: "none",
  },
  statusActive: {
    background: `${theme?.palette?.fine_pine?.main} !important`,
    color: `${theme?.palette?.basic?.white} !important`,
    pointerEvents: "none",
  },
  deleteIcon: {
    color: theme?.palette?.basic?.danger_red,
    padding: 0,
  },
}));
