import React from "react";
import { Shimmer } from "@my-scoot/component-library-legacy";
import useStyles from "./styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const AccordionTitle = ({ classes }) => {
  return (
    <div className={classes.accordionTitle}>
      <Shimmer width="100%" height="100%" borderRadius="8px" />
    </div>
  );
};

const AccordionBody = ({ classes }) => {
  return (
    <>
      <div className={classes.accordion}>
        <Shimmer width="32px" height="32px" borderRadius="100px" />
        <Shimmer width="85%" height="12px" borderRadius="8px" />
      </div>
      <div className={classes.accordion}>
        <Shimmer width="32px" height="32px" borderRadius="100px" />
        <Shimmer width="78%" height="12px" borderRadius="8px" />
      </div>
      <div className={classes.accordion}>
        <Shimmer width="32px" height="32px" borderRadius="100px" />
        <Shimmer width="58%" height="12px" borderRadius="8px" />
      </div>
      <div className={classes.accordion}>
        <Shimmer width="32px" height="32px" borderRadius="100px" />
        <Shimmer width="75%" height="12px" borderRadius="8px" />
      </div>
    </>
  );
};

const Accordion = () => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles(isDesktop);

  return (
    <div className={classes.container}>
      <AccordionTitle classes={classes} />
      <AccordionBody classes={classes} />
    </div>
  );
};

const AccordionPlaceHolder = () => {
  return <Accordion key="shimmer_1" />;
};

export default AccordionPlaceHolder;
