import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  learnMoreButton: {
    textDecoration: "underline",
    color: `${theme?.palette?.primary?.main} !important`,
  },
  buttonClassName: {
    padding: "11px 16px",
  },
  recordFooterClassName: {
    justifyContent: "space-between",
  },
  endDate: {
    justifyContent: "flex-start",
  },
  listItem: {
    padding: "0 0 0 16px",
    "& li": {
      marginTop: "16px",
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  createOfferModal: {
    padding: ({ isDesktop }) => (isDesktop ? "25px" : "15px"),
  },
  modalPaperClassName: {
    width:  ({ isDesktop }) => isDesktop && "50%",
    margin: ({ isDesktop }) => isDesktop ? 0 : "16px",
    maxWidth: "none",
  }
}));
