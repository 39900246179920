import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  creatorToolTheme,
  ThemeProvider,
} from "@my-scoot/component-library-legacy";
import ExlyTable from "common/Components/ExlyTable";
import React, { useState } from "react";
import LearnMoreModal from "ui/widgets/LearnMoreModal/LearnMoreModal";
import { useNotifications } from "utils/hooks";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import DiscountIcon from "../../../assets/images/discount.svg";
import api from "../../../data/APIs";
import dataProvider from "../../../data/dataProvider";
import { is_empty } from "../../../utils/validations";
import { ActionField, StatusField } from "./components/CustomColumns";
import CreateDiscountModal from "./components/Modals/CreateDiscountModal";
import CustomizeCouponModal from "./components/Modals/CustomizeCouponModal";
import DeleteModal from "./components/Modals/DeleteModal";
import DiscountInfoModal from "./components/Modals/DiscountInfoModal";
import EligibilityInfoModal from "./components/Modals/EligibilityInfoModal";
import useStyles from "./discounts.styles";
import { columnConfig } from "./discountsTableConfig";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { DISCOUNT_CODES_LEARN_MORE_HREFS } from "./discounts.constants";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

let form_fields = {
  code: "Discount Code",
  primary_cta_text: "Confirm",
  secondary_cta_text: "Cancel",
  del_btn: "Delete",
};

const Discounts = (props) => {
  React.useEffect(() => {
    try {
      dataProvider.postAnalyticalEvent("creatorPage", "discountCodeSection", {
        flow: "creatorPage",
        funnel: "creatorPage",
        ui_component: "Button",
        identifier: "% Discount Codes",
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const [selectedRecordData, setSelectedRecordData] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const [currentDiscountInfoId, setCurrentDiscountInfoId] = useState("");
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [selectedDiscountCode, setSelectedDiscountCode] = useState(null);

  const [showEligibility, setShowEligibility] = useState(false);
  const [currentEligibilityInfoId, setCurrentEligibilityInfoId] =
    useState(null);
  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.DISCOUNT_CODES
  );

  const classes = useStyles({ isDesktop });

  const { pushNotification } = useNotifications();

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.DISCOUNTS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const showValidityPopup = (id) => {
    setShowValidity(true);
    setCurrentDiscountInfoId(id);
  };

  const showEligibilityPopup = (id) => {
    setShowEligibility(true);
    setCurrentEligibilityInfoId(id);
  };

  if (window.document.querySelector("#save_btn")) {
    window.document.querySelector("#save_btn").parentElement.style.position =
      "relative";
    window.document.querySelector(
      ".simple-form"
    ).lastElementChild.style.display = "none";
  }

  const secondaryComponent = (
    <div className={classes.buttonsContainer}>
      <LearnMoreCta href={DISCOUNT_CODES_LEARN_MORE_HREFS.DISCOUNT_CODES} />
      {isDesktop && HAS_WRITE_ACCESS && (
        <Button
          className={classes.buttonClassName}
          color="primary"
          onClick={() => setShowDiscountModal(true)}
        >
          Create Discount Code
        </Button>
      )}
    </div>
  );

  const footerProps = {
    primarybtn: "Create Discount Codes",
    primaryClick: () => setShowDiscountModal(true),
  };

  const handleDeletePrimaryButton = async () => {
    if (is_empty(selectedRecordData) || is_empty(selectedRecordData.uuid))
      return;
    try {
      dataProvider.postAnalyticalEvent("discountCodePage", "confirmDeleting", {
        flow: "discountCode",
        funnel: "discountCodePage",
        ui_component: "Text Button",
        identifier: form_fields.primary_cta_text,
        booking_meta: JSON.stringify(selectedRecordData),
      });
      const status = await dataProvider.custom_request(
        api.delete_discount,
        "POST",
        { uuid: selectedRecordData.uuid }
      );
      pushNotification(status.message, {
        variant: "outlined",
        color: "primary",
      });
      setShowDelete(false);
      if (status.status === 200) {
        const analytic = await dataProvider.postAnalyticalEvent(
          "discountCodePage",
          "codeDeleted",
          {
            flow: "discountCode",
            funnel: "discountCodePage",
            booking_meta: JSON.stringify(selectedRecordData),
          }
        );
        if (analytic.status === 200) {
          window.location.reload();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteSecondaryButton = () => {
    try {
      dataProvider.postAnalyticalEvent("discountCodePage", "cancelDeteleting", {
        flow: "discountCode",
        funnel: "discountCodePage",
        ui_component: "Text Button",
        identifier: form_fields.secondary_cta_text,
        booking_meta: JSON.stringify(selectedRecordData),
      });
      setShowDelete(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="component-wrapper">
      <ExlyTable
        tableTitle="Discount Codes"
        ra_props={props}
        columnConfig={columnConfig({
          showValidityPopup,
          showEligibilityPopup,
        })}
        footerProps={footerProps}
        hasMobileFooter={HAS_WRITE_ACCESS}
        layoutProps={{
          secondaryComponent: isDesktop && secondaryComponent,
          paddingDesktop: "0",
          paddingBottom: "120px",
          paddingMobile: "0",
          noMobileBoxShadow: true,
          learnMoreHref: DISCOUNT_CODES_LEARN_MORE_HREFS.DISCOUNT_CODES,
        }}
        primaryKey="id"
        variant="striped"
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        renderSecondaryAction={(record) =>
          HAS_WRITE_ACCESS ? (
            <ActionField
              record={record}
              form_fields={form_fields}
              setSelectedRecordData={setSelectedRecordData}
              setShowDelete={setShowDelete}
            />
          ) : (
            <div />
          )
        }
        primaryActions={[
          {
            renderCustomAction: (record) =>
              !isDesktop && <StatusField record={record} />,
          },
        ]}
        desktopCustomPrimaryAction={
          HAS_WRITE_ACCESS && {
            renderCustomAction: (record) => (
              <ActionField
                record={record}
                form_fields={form_fields}
                setSelectedRecordData={setSelectedRecordData}
                setShowDelete={setShowDelete}
              />
            ),
          }
        }
        noExportButton
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "Create Discount Codes",
          description:
            "As soon as we have a new discount code, it will show up here. View the video to learn more.",
          videoSrc: DISCOUNT_CODES_LEARN_MORE_HREFS.DISCOUNT_CODES_VIDEO,
          playerProps: {
            playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
          },
          primaryCtaProps: {
            title: "Create Discount Codes",
            onClick: () => setShowDiscountModal(true),
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: DISCOUNT_CODES_LEARN_MORE_HREFS.DISCOUNT_CODES,
          },
          className: "mt-4",
        }}
      />

      <ThemeProvider theme={creatorToolTheme}>
        <DiscountInfoModal
          showModal={showValidity}
          onClose={() => setShowValidity(false)}
          discountId={currentDiscountInfoId}
        />
        <EligibilityInfoModal
          showModal={showEligibility}
          onClose={() => setShowEligibility(false)}
          onClickClose={() => setShowEligibility(false)}
          eligibilityId={currentEligibilityInfoId}
        />
        <DeleteModal
          record={selectedRecordData}
          showModal={showDelete}
          onClose={() => setShowDelete(false)}
          onClickPrimaryButton={handleDeletePrimaryButton}
          onClickSecondaryButton={handleDeleteSecondaryButton}
        />
        <LearnMoreModal
          open={showLearnMore}
          title="How To Use Discount Codes"
          icon={DiscountIcon}
          onClose={() => setShowLearnMore(false)}
          list={[
            "Set a discount value and create a discount code name",
            "Set a usage limit for the code",
            "Customers will use the discount code to get the set discount on any of your offerings",
          ]}
        />
        {showDiscountModal && (
          <CreateDiscountModal
            open={showDiscountModal}
            onClose={() => setShowDiscountModal(false)}
            setSelectedDiscountCode={setSelectedDiscountCode}
          />
        )}
        {selectedDiscountCode && (
          <CustomizeCouponModal
            selectedDiscountCode={selectedDiscountCode}
            setSelectedDiscountCode={setSelectedDiscountCode}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

export default Discounts;
