import React from "react";
import style from "./Style.module.css";

export const ConfirmationPopup = (props) => {
  const { title, action, setConfirmationModal, handleDelete, handleEndOffer } =
    props;
    console.log('actions', action, title)
  return (
    <div className={style.confirmationContainer}>
      <div className={style.confirmationtitle}>{title}</div>
      {(action === "Delete" ||
        action === "End Offer") && (
          <div className={style.confirmbuttonContainer}>
            <button
              className={style.confirmdelete}
              onClick={
                action === "Delete"
                  ? handleDelete
                  : action === "End Offer"
                  ? handleEndOffer
                  : null
              }
            >
              {action === "End Offer" ? "End Offer" : "Delete"}
            </button>
            <button
              className={style.confirmcancel}
              onClick={() => setConfirmationModal(false)}
            >
              Cancel
            </button>
          </div>
        )}
    </div>
  );
};
