import React, { useCallback, useMemo } from "react";
import { useRefresh } from "react-admin";
import { Form } from "react-final-form";

import ExlyModal from "common/Components/ExlyModal";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./styles";
import { coupon_labels } from "ui/pages/discounts/discounts.constants";
import CouponFormFields from "./components/CouponFormFields/CouponFormFields";
import { createPromotionCoupon } from "ui/pages/discounts/discounts.helpers";
import { useNotifications } from "utils/hooks";
import constants from "constants/constants";
import useCreateDiscounts from "ui/pages/discounts/useCreateDiscounts";

const CustomizeCouponModal = (props) => {
  const { selectedDiscountCode, setSelectedDiscountCode } = props;
  const refresh = useRefresh();
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const { pushNotification } = useNotifications();
  const { form_fields } = useCreateDiscounts(props);

  const onClose = useCallback(() => {
    setSelectedDiscountCode(null);
    refresh();
  }, [setSelectedDiscountCode, refresh]);

  const initialValues = useMemo(() => {
    if (!selectedDiscountCode) return {};
    const { code, value, type, currency, discount_uid } = selectedDiscountCode;
    return {
      discountCodeName: code,
      header: "WOW! you won a coupon",
      tagline: `${
        type === form_fields.absolute_type
          ? `${constants.CURRENCY_SYMBOL_MAP[currency]}${value}`
          : `${value}%`
      } OFF`,
      description: "Purchase “OFFERING” to use the coupon code.",
      button_text: "APPLY THE CODE",
      discount_uid,
      discount: {
        value,
        type,
        currency,
      },
    };
  }, [selectedDiscountCode]);

  const onSubmit = useCallback(
    (formState) => {
      const { discount_uid, header, tagline, description, button_text } =
        formState;
      const payload = {
        discount_uid,
        promotional_data: {
          header,
          tagline,
          description,
          button_text,
        },
      };
      createPromotionCoupon(payload)
        .then(() => {
          pushNotification("Coupon has successfully been created", {
            variant: "outlined",
            color: "fine_pine",
          });
          onClose();
        })
        .catch((err) => {
          pushNotification(err?.message, {
            variant: "outlined",
            color: "coral_red",
          });
        });
    },
    [onClose, pushNotification]
  );

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, ...restFormProps }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ExlyModal
              open={selectedDiscountCode}
              onClose={() => {
                onClose();
                form.reset();
              }}
              title={
                isDesktop
                  ? coupon_labels.desktop_modal_title
                  : coupon_labels.mobile_modal_title
              }
              onPrimaryBtnClick={handleSubmit}
              primaryBtnText={coupon_labels.cta_text}
              primaryBtnProps={{ type: "submit", disabled: submitting }}
              modal_props={{ modalPaperClassName: classes.desktopModalPaper }}
              mobile_modal_props={{}}
            >
              <CouponFormFields {...restFormProps} />
            </ExlyModal>
          </form>
        );
      }}
    </Form>
  );
};

export default CustomizeCouponModal;
