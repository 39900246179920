import { api, dataProvider } from "data";

export const createPromotionCoupon = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider.custom_request(api.create_promotion_coupon, "POST", payload)
    .then(({ status, message }) => {
      if (status !== 200) reject(`Error while creating promotion coupon :${message}`);
      resolve();
    })
    .catch(reject);
  });
