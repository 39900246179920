import { OFFERING_STATUSES } from "constants/schedule";
import { api, dataProvider } from "data";
import { useEffect, useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { useNotifications } from "utils/hooks";
import { isValidEmail, is_empty } from "utils/validations";

const useCreateDiscounts = (props) => {
  const { onClose, listingMultiSelect, setSelectedDiscountCode } = props;

  const couponApplicable = {
    everyone: 1,
    specificListings: 2,
    loyaltyUsers: 4,
  };

  const CustomerEligibility = {
    everyone: 1,
    specificGroups: 2,
  };

  let form_fields = {
    code: "Discount Code",
    percentage: "Discount Value",
    usage: "Usage Limit",
    save_btn: "Create",
    absolute_type: 2,
    percentage_type: 1,
  };

  const { pushNotification } = useNotifications();
  const notify = useNotify();
  const refresh = useRefresh();

  // ---------------- new-code
  const [listing_data, set_listing_data] = useState({});
  const [audienceList, setAudienceList] = useState({});
  const [loading, setLoading] = useState(false);

  const addDiscountCoupon = async (formState) => {
    let params = {
      discount: {
        code: formState.code,
        value: parseFloat(formState.value),
        usage_limit: parseInt(formState.usage_limit) || 0,
        type: formState.type,
        applicability: Number(formState.applicability),
        include_gst: formState.include_gst,
        currency: formState.currency,
      },
    };

    if (formState.listing_uuids.length > 0) {
      if (typeof formState.listing_uuids === "string") {
        params["listing_uuids"] = [formState.listing_uuids];
      } else {
        params["listing_uuids"] = formState.listing_uuids;
      }
    }

    if (formState.type === form_fields.absolute_type) {
      Object.assign(params, { currency: formState.currency });
    }

    if (formState.applicability == couponApplicable.loyaltyUsers) {
      Object.assign(params, { audience: formState.audience });
    }

    try {
      dataProvider.postAnalyticalEvent("discountCodePage", "create", {
        flow: "discountCode",
        funnel: "discountCodePage",
        ui_component: "Button",
        identifier: form_fields.save_btn,
      });

      const data = await dataProvider.custom_request(
        api.create_discount,
        "POST",
        params
      );

      pushNotification(data.message, { variant: "outlined", color: "primary" });

      if (data.status === 200) {
        dataProvider.postAnalyticalEvent("discountCodePage", "codeGenerated", {
          flow: "discountCode",
          funnel: "discountCodePage",
          ui_component: "Banner",
          identifier: "Green Banner with White Color Text",
          booking_meta: JSON.stringify(params),
        });
      }

      if (listingMultiSelect) {
        onClose();
        if (!formState.is_promotional_coupon) {
          refresh();
        } else if (setSelectedDiscountCode) {
          setSelectedDiscountCode({
            ...formState,
            discount_uid: data.data?.discount_uid,
          });
        }
      } else {
        return {
          listingId: formState.listing_uuids,
          couponCode: params.discount.code,
        };
      }
    } catch (e) {
      console.log({ newError: e });

      dataProvider.postAnalyticalEvent("discountCodePage", "errorOccured", {
        flow: "discountCode",
        funnel: "discountCodePage",
        ui_component: "Footer",
        identifier: "Red Text Appearing Beneath Create Button ",
        booking_meta: e.body.message,
      });

      if (e.body && e.body.message) {
        /* TODO: pushNotification causing form rerender- causing clearning form on error need to fix it */
        // pushNotification(e.body.message, { variant: "outlined", color: "primary" });  // causing form clear bug
        notify(e.body.message);
      }
    }
  };

  const handleDeleteOption = ({ groupBy, key }, form) => {
    let data = form.getState().values.audience;
    let updatedState;

    if (groupBy === "Recipient Groups (Click on group to add)") {
      let updatedGroup = data.groups;
      updatedGroup = updatedGroup.filter((item) => item !== key);
      updatedState = { ...data, groups: [...updatedGroup] };
    } else {
      let updatedExtras = data.extras;
      updatedExtras = updatedExtras.filter((item) => item !== key);
      updatedState = { ...data, extras: [...updatedExtras] };
    }

    form.change("audience", updatedState);
  };

  const getSpecificGroupsOrCustomersOptions = (filterQuery) => {
    let customers = audienceList?.customers?.filter((customer) => {
      return customer && customer.search(filterQuery) !== -1;
    });

    const options = [
      ...(audienceList.groups
        ? Object.keys(audienceList.groups[0]).map((key) => ({
            groupBy: "Recipient Groups (Click on group to add)",
            key: audienceList.groups[0][key],
          }))
        : []),
      ...(customers
        ? customers.map((customer) => ({
            groupBy: "Recipients",
            key: customer,
          }))
        : []),
    ];

    if (isValidEmail(filterQuery)) options.unshift({ key: filterQuery });

    return options;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      const data = await dataProvider.custom_request(
        api.fetch_offerings,
        "GET",
        { status: OFFERING_STATUSES.live.value, page: "all" }
      );
      if (data.status === 200 && !is_empty(data.data)) {
        set_listing_data(data.data);
      }

      // get audience list
      const audienceData = await dataProvider.custom_request(
        api.get_audiance_list,
        "GET"
      );

      if (audienceData.status === 200 && !is_empty(audienceData.data)) {
        setAudienceList(audienceData.data.audiences);
      }

      setLoading(false);
    })();
  }, []);

  const offeringsOptions = listing_data?.listings?.map((listing) => ({
    ...listing,
    label: listing.title,
    value: listing.uuid,
  }));

  const customerEligibilityRadioOptions = [
    { label: "Everyone", value: CustomerEligibility.everyone },
    {
      label: "Specific groups or customers",
      value: CustomerEligibility.specificGroups,
    },
  ];

  return {
    getSpecificGroupsOrCustomersOptions,
    offeringsOptions,
    couponApplicable,
    CustomerEligibility,
    addDiscountCoupon,
    handleDeleteOption,
    form_fields,
    loading,
    customerEligibilityRadioOptions,
  };
};

export default useCreateDiscounts;
