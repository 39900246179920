export const coupon_api_keys = {
  header: "header",
  tagline: "tagline",
  description: "description",
  button_text: "button_text",
  discount_uid: "discount_uid",
};

export const coupon_labels = {
  mobile_modal_title: "Customize Your Coupon",
  desktop_modal_title: "Customize Your Coupon Code",
  cta_text: "Create coupon",
  discount_code: "Discount Code",
  header: "Header *",
  tagline: "Tagline *",
  header_placeholder: "Enter header",
  tagline_placeholder: "Enter tagline",
  description: "Description *",
  description_placeholder: "Enter description",
  button_text: "Button Text *",
  button_text_placeholder: "Enter button text",
};

export const DISCOUNT_CODES_LEARN_MORE_HREFS = {
  DISCOUNT_CODES:
    "https://support.exlyapp.com/support/solutions/articles/84000347733-discount-codes-on-exly",
  DISCOUNT_CODES_VIDEO: "https://www.youtube-nocookie.com/embed/qHqSzJWFMAU",
};
