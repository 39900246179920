import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    contentContainer: {
      marginBottom: (isDesktop) => (isDesktop ? "" : "70px"),
      zIndex: "2",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
