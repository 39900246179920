import React from "react";
import moment from "moment";
import Slider from "react-slick";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@my-scoot/component-library-legacy";

import useStyles from "./styles";
import constants from "constants/constants";

function SamplePrevArrow(props) {
  const { onClick } = props;
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  if (!onClick) return null;
  return (
    <IconButton
      className={classnames(classes.arrow, classes.prevArrow)}
      onClick={onClick}
    >
      <ArrowBackIosRoundedIcon />
    </IconButton>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  if (!onClick) return null;
  return (
    <IconButton
      className={classnames(classes.arrow, classes.nextArrow)}
      onClick={onClick}
    >
      <ArrowForwardIosRoundedIcon />
    </IconButton>
  );
}

const settings = {
  dots: true,
  infinite: false,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
};

function CouponPreviewCarousel({ values }) {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const { header, tagline, description, button_text, discountCodeName } =
    values;
  const expiryDescription = `Valid till ${moment()
    .add(30, "days")
    .format(constants.displayDateYearFormat)}`;

  return (
    <div className={classes.carouselWrap}>
      <Slider
        {...settings}
        className={classes.carousel}
        trackStyle={{ display: "flex", alignItems: "center" }}
      >
        <div className={classes.slideWrap}>
          <div className="text-center">Discount Card Preview</div>
          <div className={classnames(classes.exitPopup, classes.discountPopup)}>
            <div className={classes.discountCodeName}>{discountCodeName}</div>
            <div>{description}</div>
            <div className="font_600">{expiryDescription}</div>
          </div>
        </div>
        <div className={classes.slideWrap}>
          <div className="text-center">Exit Intent Popup Preview </div>
          <div className={classnames(classes.exitPopup)}>
            <div className="font_600">{header}</div>
            <div className={classes.discountOffer}>{tagline}</div>
            <div className={classes.description}>{description}</div>
            <Button
              color="tertiary"
              variant="outlined"
              fullWidth
              className={classes.applyBtn}
            >
              {button_text}
            </Button>
            <div className={classes.expiryDescription}>{expiryDescription}</div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default CouponPreviewCarousel;
