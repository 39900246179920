import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    container: {
      width: "100%",
      display: "inline-block",
      "& > div:last-child": {
        marginBottom: "3px",
      },
    },
    accordionTitle: {
      height: "12px",
      width: "160px",
      margin: "20px 0",
    },
    accordion: {
      display: "flex",
      flexDirection: "row",
      height: "48px",
      background: "#FFFFFF",
      border: "1.5px solid #E7E6E4",
      boxShadow: "0px 3px 0px rgba(14, 13, 12, 0.14)",
      borderRadius: "12px",
      alignItems: "center",
      gap: "12px",
      padding: "8px 12px",
      marginBottom: "20px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
