import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/WarningRounded";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import React from "react";

const useStyles = makeStyles((theme) => ({
  modalPaperClassName: {
    minWidth: "532px",
  },
  primaryBtnClassName: {
    backgroundColor: `${theme?.palette?.basic?.danger_red} !important`,
  },
  desktopModalHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10.47px",
    height: "22px",
  },
  mobileModalHeader: {
    display: "flex",
    alignItems: "center",
    gap: "12.94px",
    height: "22px",
  },
  warningIcon: {
    color: theme?.palette?.basic?.danger_red,
  },
  desktopWarningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "9.81px",
    padding: "24px",
    "& svg": {
      fontSize: "97.38px",
      color: theme?.palette?.basic?.danger_red,
    },
  },
  mobileWarningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.81px",
    padding: "16px",
    "& svg": {
      fontSize: "97.38px",
      color: theme?.palette?.basic?.danger_red,
    },
  },
  warningDescription: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 500,
    width: "100%",
  },
  discountCode: {
    fontWeight: 700,
  },
}));

const DeleteModal = ({
  record,
  showModal,
  onClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
}) => {
  const classes = useStyles();
  const isDesktop = window.screen.width > constants.mobile_width;

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.modalPaperClassName}
      primaryBtnClassName={classes.primaryBtnClassName}
      open={showModal}
      onClose={onClose}
      showCross
      primaryBtnText="Delete"
      onPrimaryBtnClick={onClickPrimaryButton}
      secondaryBtnText="Cancel"
      onSecondaryBtnClick={onClickSecondaryButton}
      title={
        <div className={classes.desktopModalHeader}>
          <WarningIcon className={classes.warningIcon} />
          <span>Delete Discount Code</span>
        </div>
      }
    >
      <div className={classes.desktopWarningWrapper}>
        <WarningIcon />
        <div className={classes.warningDescription}>
          Are you sure you want to delete the discount code{" "}
          <span className={classes.discountCode}>{record.code}</span>?
        </div>
      </div>
    </Modal>
  ) : (
    <MobileModal
      open={showModal}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      primaryAction={{
        label: "Delete",
        className: classes.primaryBtnClassName,
        onClick: onClickPrimaryButton,
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: onClickSecondaryButton,
      }}
      header={
        <div className={classes.mobileModalHeader}>
          <WarningIcon className={classes.warningIcon} />
          <span>Delete Discount Code</span>
        </div>
      }
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.mobileWarningWrapper}>
        <WarningIcon />
        <div className={classes.warningDescription}>
          Are you sure you want to delete the discount code{" "}
          <span className={classes.discountCode}>{record.code}</span>?
        </div>
      </div>
    </MobileModal>
  );
};

export default DeleteModal;
